<template>
  <el-card class="box-card mt-3" id="highlights">
    <div v-if="courses_combo">
      <el-carousel :interval="60000" type="card" height="500px">
        <el-carousel-item v-for="(item, index) in courses_combo" :key="index">
          <CarouselCourseComboItem
              :item="item"
              :promotion_branch="promotion_branch"
              :first_classroom_every_courses="first_classroom_every_courses"
              @change-course="emitChangeCourse"
          ></CarouselCourseComboItem>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div v-else>Không có thông tin khóa học</div>
  </el-card>
</template>

<script>
import CarouselCourseComboItem from "./CarouselCourseComboItem";
import '@/assets/css/advise.css';

export default {
  name: "CarouselCourseCombo",
  components: {CarouselCourseComboItem},
  props: {
    courses_combo: {
      default: () => {
        return [];
      }
    },
    promotion_branch: Object,
    first_classroom_every_courses: Array,
  },
  methods: {
    emitChangeCourse(course_id_first) {
      this.$emit('change-course', course_id_first)
    }
  }
}
</script>

<style>

</style>