<template>
  <el-card class="box-card mt-3" id="content">
    <div slot="header" class="clearfix">
      <span><h4>Nội dung bài học</h4></span>
    </div>
    <div v-if="firstCourse && firstCourse.content" v-html="firstCourse.content">
    </div>
    <div v-else>Không có nội dung bài học</div>
  </el-card>
</template>

<script>
export default {
  name: "LessonContent",
  props: {
    firstCourse: {
      type: Object,
      default: () => {
        return {};
      }
    }
  }
}
</script>

<style scoped>

</style>