<template>
  <div class="swiper-slide swiper-slide-visible swiper-slide-active" role="group" aria-label="1 / 1"
       style="transition-duration: 0ms; transform: translate3d(0px, 0px, 0px) rotateX(0deg) rotateY(0deg) scale(1); z-index: 1;">
    <div class="edu-card">
      <div class="edu-card-header">
        <div class="edu-image">
          <div class="teacher-card">
            <div class="teacher-detail">
              <h4>Khóa {{ item.name }}</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="edu-card-body">
        <h3 class="title-single"><a href="">{{ item.name }}</a></h3>
        <div class="detail">
          <img :src="publicPath + `/media/images/center-detail/center.svg`" alt="">
          <router-link
              :to="{ path: '/detail-center/'+ item.center_id }"
              style="text-decoration: unset"
          >
            {{ item.center ? item.center.name : '' }}
          </router-link>
        </div>
        <p class="detail">
          <img :src="publicPath + `/media/images/center-detail/position-marker.svg`" alt="">
          <b> {{ promotion_branch.address }} </b>
        </p>
        <p class="detail">
          <img src="https://beta-noibo.edutalk.edu.vn/images/tuvanvien/opening-times.svg" alt="">
          <b v-if="current_classroom && current_classroom.start_date">
            {{ current_classroom.start_date | formatDate }}
          </b>
          <b v-else>Chưa có lớp sắp khai giảng</b>
        </p>
        <p class="price">{{ item.fee |formatVND }}</p>
      </div>
      <div class="edu-card-footer">
        <p class="students">
          <img :src="publicPath + `/media/images/center-detail/user_amount.svg`" alt="">
          <b>Đã có {{ item.student_register_course.length }} đã đăng ký</b>
        </p>
        <button class="btn-xem-chi-tiet" @click="seeMore()">Xem chi tiết</button>
      </div>
    </div>
    <div class="swiper-slide-shadow-left" style="opacity: 0; transition-duration: 0ms;"></div>
    <div class="swiper-slide-shadow-right" style="opacity: 1.05136e-16; transition-duration: 0ms;"></div>
  </div>
</template>

<script>
import '@/assets/css/tuvanvien.scss';
import '@/assets/css/advise.css';

export default {
  name: "CarouselCourseComboItem",
  props: {
    item: Object,
    promotion_branch: Object,
    first_classroom_every_courses: Array,
  },
  data() {
    return {
      publicPath: process.env.VUE_APP_BASE_URL,
      current_classroom: null
    }
  },
  mounted() {
    this.current_classroom = this.getCurrentClassroom();
  },
  methods: {
    seeMore() {
      this.$router.push({
        name: 'advise-detail-course-combo',
        query: {
          ...this.$route.query,
          course_id: this.item.id,
        }
      })
      this.$emit('change-course', this.item.id)
    },
    getCurrentClassroom() {
      let arr = this.first_classroom_every_courses;
      for (let i = 0; i < arr.length; i++) {
        if (arr[i]['course_id'] && arr[i]['course_id'] == this.item.id) {
          return arr[i];
        }
      }
      return null;
    }
  }
}
</script>

<style scoped>
  .btn-xem-chi-tiet {
    color: #886ab5;
    border: none;
    background-color: transparent;
  }
</style>