<template>
  <div>
    <el-card class="box-card mt-3 sticky-course">
      <div class="detail-classroom mt-2">
        <div class="col-sm-12 mt-20 detail-box">
          <h3 class="price">{{ firstCourse.fee |formatVND }}</h3>
          <div v-if="classroom && classroom.number_student">
            còn {{ classroom.number_student - classroom.current_student }} suất
          </div>
          <div v-if="classroom.id">
            <div class="detail-classroom mt-2" v-if="classroom.name">
              <h4>Lớp {{ classroom.name }}</h4>
            </div>
            <div class="detail-classroom mt-2">
              <img class="mr-2" :src="`${publicPath}/media/images/center-detail/opening-times.svg`" width="22"
                   height="22"
                   alt="">
              <a><span v-if="classroom.start_date">Khai giảng: {{ classroom.start_date | formatDate }}</span></a>
            </div>
            <div class="detail-classroom mt-2">
              <img class="mr-2" :src="`${publicPath}/media/images/center-detail/user_amount.svg`" width="22" height="22"
                   alt="">
              <div style="display: inline">
              <span>
                  <el-button type="text" @click="showStudent">{{ classroom.current_student }}</el-button>
                <span v-if="classroom.type != 'trial'">/{{ classroom.number_student }}</span> học viên
              </span>
              </div>
            </div>
            <div class="detail-classroom mt-2">
              <img class="mr-2" :src="`${publicPath}/media/images/center-detail/time.svg`" width="22" height="22"
                   alt="">
              <span>{{
                  classroom.schedules.length ? `${classroom.schedules[0].start_date}` + '-' + `${classroom.schedules[0].end_date}` + ' ' + `${classroom.schedules[0].day}` : ''
                }}</span>
            </div>
            <div class="detail-classroom mt-2">
              <img class="mr-2" :src="`${publicPath}/media/images/center-detail/video.svg`" width="22" height="22"
                   alt="">
              <span>{{ (Number(classroom.number_lesson) + Number(classroom.number_test)) }} Buổi</span>
            </div>
            <div class="detail-classroom mt-2">
              <img class="mr-2" :src="`${publicPath}/media/images/center-detail/research-center.svg`" width="22"
                   height="22" alt="">
              <router-link v-if="classroom.branch && classroom.branch.center"
                           :to="{ path: '/detail-center/'+ classroom.branch.center.id }"
                           style="text-decoration: unset"
              >{{ classroom.branch.center.name }}
              </router-link>
            </div>
            <div class="detail-classroom mt-2">
              <img class="mr-2" :src="`${publicPath}/media/images/center-detail/position-marker.svg`" width="22"
                   height="22" alt="">
              <span>{{ classroom.branch ? classroom.branch.address : '' }}</span>
            </div>
          </div>
          <div class="detail-classroom mt-2" v-else>
            <span>Khóa học này chưa có lớp sắp khai giảng</span>
          </div>
        </div>
      </div>
      <div class="mt-2 d-flex justify-content-center">
        <div v-if="this.$route.query.payment == 1">
          <router-link
              class="btn btn-danger"
              :to="{name :'customer-result', query : {
                  customer_id : this.$route.query.customer_id,
                  promotion_id : this.$route.query.promotion_id,
                  classroom_id : this.$route.query.classroom_id,
                  payment : this.$route.query.payment,
                  course_id: this.$route.query.course_id,
                  branch_id: this.$route.query.branch_id

      }}"><i class="el-icon-s-claim"></i> Đặt lịch đóng tiền
          </router-link>
        </div>
        <div v-else class="d-flex justify-content-center">
          <button class="btn btn-primary" @click="handleCreateContract">Demo tạo đơn hàng</button>
        </div>
      </div>

    </el-card>
    <el-dialog :title="dialog.title" class="responsive contract" :visible.sync="dialogFormVisible">
      <el-row :gutter="10" class="pr-2 pl-2">
        <contract-form
            v-if="dialogFormVisible"
            :customer-id="customer_id"
            :center-id="payment_schedule.center_id"
            :branch-id="payment_schedule.branch_id"
            :contract="contract"
            :check-edit="checkEdit"
            :check-view="checkView"
            @close="closeDialog"
        >
        </contract-form>
      </el-row>
    </el-dialog>
  </div>
</template>


<script>
import {GET_LIST_STUDENT} from "@/core/services/store/tuVan.module";
import moment from "moment-timezone";
import ContractForm from "@/views/pages/customer/contract-form";
import {GET_INFO_PAYMENT_SCHEDULE} from "@/core/services/store/customer/customer.module";

export default {
  name: "ContractRegister",
  components: {ContractForm},
  props: ['firstCourse', 'classroom'],
  data() {
    return {
      classroom_id: this.classroom.id,
      publicPath: process.env.VUE_APP_BASE_URL,
      dialogFormVisible: false,
      dialog: {
        title: ''
      },
      contract: null,
      payment_schedule: {
        'center_id': '',
        'branch_id': '',
        'ngay_hen': ''
      },
      checkView: false,
      checkEdit: false,
      customer_id: null,
      info: {}
    }
  },
  mounted() {
    this.payment_schedule.center_id = this.$route.query.center_id;
    this.payment_schedule.branch_id = this.$route.query.branch_id;
    console.log(this.payment_schedule)
    this.getInfoBeforePaymentSchedule();
  },
  methods: {
    getInfo() {
      if (this.$route.query.promotion_id) {
        this.info.promotion_id = this.$route.query.promotion_id;
      }
      if (this.$route.query.classroom_id) {
        this.info.classroom_id = this.$route.query.classroom_id;
      }
      if (this.$route.query.customer_id) {
        this.info.customer_id = this.$route.query.customer_id;
      }
      if (this.$route.query.search_course) {
        this.info.search_course = this.$route.query.search_course;
      }
      if (this.$route.query.branch_id) {
        this.info.branch_id = this.$route.query.branch_id;
      }
      if (this.$route.query.branch_id) {
        this.payment_schedule.branch_id = this.$route.query.branch_id ?? 0;
      }
    },
    closeDialog() {
      this.dialogFormVisible = false;
    },
    showStudent() {
      let payload = {
        class_id: this.classroom.id,
      };
      this.$store.dispatch(GET_LIST_STUDENT, payload).then((response) => {
        if (!response.error) {
          let students = response.data;
          let data_students = students.map((item) => {
            if (item.user) {
              return {
                created_at: this.formatDate(item.created_at),
                user_code: item.user.id + 100000,
                name: item.user.name
              }
            }
          });
          this.$emit('openDetail', data_students)
        }
      });

    },
    handleCreateContract() {
      this.dialog.title = 'Demo tạo đơn hàng';
      this.dialogFormVisible = true;
      this.customer_id = this.$route.params.customer_id;
      this.center_id = this.$route.params.center_id;
      this.checkEdit = false;
      this.checkView = false;
    },
    formatDate(value) {
      if (value) {
        return moment(String(value)).format('DD-MM-YYYY')
      }
    },
    async getInfoBeforePaymentSchedule() {
      await this.getInfo();
      await this.$store.dispatch(GET_INFO_PAYMENT_SCHEDULE, this.info).then((res) => {
        if (!res.error) {
          if (res.data.max_ngay_hen) {
            this.payment_schedule.ngay_hen = res.data.max_ngay_hen.ngay_hen_dong_tien ?? '';
            this.ngayHenOld = res.data.max_ngay_hen.ngay_hen_dong_tien ?? '';
          }
          this.contracts = res.data.list_contract;
          this.$forceUpdate();
        } else {
          this.$notify({
            title: 'Lấy thông tin đặt lịch đóng tiền',
            message: res.message,
            type: 'error'
          });
        }});
    },
  }
}
</script>

<style>
.price {
  color: red;
  font-weight: bold;
  font-size: 30px;
}

.sticky-course {
  position: -webkit-sticky;
  position: sticky;
  top: 124px;
  z-index: 3;
}

</style>
