<template>
  <el-card class="box-card mt-3" id="rating-and-comment">
    <div slot="header" class="clearfix">
      <span><h4>Đánh giá và bình luận</h4></span>
    </div>
    <div class="review-content" v-if="ratings.length">
      <CardRateAndComment v-for="(item, index) in ratings" :key="index" :rating="item">
      </CardRateAndComment>
    </div>
  </el-card>
</template>

<script>
import CardRateAndComment from "./CardRateAndComment";

export default {
  name: "RateAndComment",
  components: {CardRateAndComment},
  props: {
    ratings: {
      type: Array,
      default: () => {
        return [];
      }
    }
  }
}
</script>

<style scoped>

</style>