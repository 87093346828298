<template>
  <div class="__header mb-3 sticky-header-class" v-if="firstCourse">
    <p class="bold center-name mt-2">{{ firstCourse.name }}</p>
    <div class="description">{{ firstCourse.excerpt }}</div>
    <el-card class="box-card mt-3" id="edutalk-guard">
      <el-tabs @tab-click="jumpToHTMl">
        <el-tab-pane label="Thông tin khóa học" class="common-active" name="info-course">
        </el-tab-pane>
        <el-tab-pane label="Nội dung học" class="common-active" name="content">
        </el-tab-pane>
        <el-tab-pane v-if="classroom" label="Giảng viên" class="common-active" name="giao_vien">
        </el-tab-pane>
        <el-tab-pane label="Đánh giá và bình luận" class="common-active" name="rating-and-comment">
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>
<script>
export default {
  name: "Header",
  props: {
    firstCourse: {},
    classroom: {}
  },
  methods: {
    jumpToHTMl(event) {
      let id = event.name;
      var targetEle = document.getElementById(id);
      let pos = targetEle.style.position;
      let top = targetEle.style.top;
      targetEle.style.position = 'relative';
      targetEle.style.top = '-220px';
      targetEle.scrollIntoView({behavior: 'smooth', block: 'start'});
      targetEle.style.top = top;
      targetEle.style.position = pos;
    },
  },
}
</script>

<style>
div.el-tabs__item[aria-controls='pane-edutalk-guard'] {
  background: #886ab5;
  color: yellow;
  border-radius: 0.25rem;
}

.el-tabs__nav-wrap::after {
  z-index: -1 !important;
}

.sticky-header-class {
  position: -webkit-sticky;
  position: sticky;
  top: 30px;
  z-index: 3;
}

.el-tabs__header {
  margin: 12px 0 !important;
}

.center-name {
  font-size: 27px;
}

</style>