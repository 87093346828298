<template>
  <el-card class="box-card mt-3" id="feedback">
    <div slot="header" class="clearfix">
      <div id="giao_vien" class="col-sm-12">
        <div class="section-detail teacher">
          <div class="row">
            <div class="col-sm-6">
              <h3 class="title">Giáo viên </h3>
              <div class="d-flex justify-content-center teacher-info">
                <div class="avatar">
                  <img v-if="teacher && teacher.avatar" :src="`${teacher.avatar}`" style="width: 100px;" class="avatar-teacher"
                       alt="SmartAdmin WebApp" aria-roledescription="logo">
                  <img v-else :src="publicPath + 'media/images/center-detail/man.svg'" class="avatar-teacher"
                       alt="SmartAdmin WebApp"
                       aria-roledescription="logo">
                </div>
                <div class="name-box">
                  <div class="name-tick">
                    <h3>{{ teacher.name }} <span class="tick"></span></h3>
                  </div>
                  <div class="review">
                    <div class="rating left">
                      <div class="stars right">
                        <el-rate
                            v-model="value"
                            disabled
                            show-score
                            text-color="#ff9900"
                            score-template="">
                        </el-rate>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="name-tick">
                <h3 class="title">Chứng chỉ</h3>
                <!--                <div class="tick"></div>-->
              </div>
              <div class="information-certificate">
                <div v-if="teacher &&  teacher.certificate_image && teacher.certificate_image.length">
                  <img v-for="(item, index) in teacher.certificate_image" :key="index" type="image"
                       class="image-certificate" :src="`${item.path}`" alt="">
                </div>
              </div>
            </div>
            <div class="col-sm-12 mt-4">
              <div class="content" v-if="teacher.teacher_infor" v-html="teacher.teacher_infor.description">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
    </div>
  </el-card>
</template>

<script>
export default {
  name: "TeacherCourse",
  props: {
    teacher: {
      type: Object,
      default: () => {
        return {};
      }
    },
    classroom: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      value: 0,
      publicPath: process.env.VUE_APP_BASE_URL,
    }
  },
  mounted() {
    this.value = this.classroom.avg_teacher_rate
  }
}
</script>

<style>
.avatar-teacher {
  background: #cecece;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 1px solid #cecece;
}

.name-tick .tick {
  position: absolute;
  margin-left: 5px;
  width: 24px;
  height: 24px;
  background: red;
  background: url("/media/svg/checked.svg") no-repeat;
}
.teacher-info{
  padding-top: 53px;
}
</style>