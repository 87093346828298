<template>
  <el-card class="box-card mt-3" id="info-course">
    <div slot="header" class="clearfix">
      <span><h4>Thông tin khóa học</h4></span>
    </div>
    <div v-if="firstCourse.description" v-html="firstCourse.description">
    </div>
    <div v-else>Không có thông tin khóa học</div>
  </el-card>
</template>

<script>
export default {
  name: "CourseInfo",
  props: {
    firstCourse: {
      type: Object,
      default: () => {
        return {};
      }
    }
  }
}
</script>
<style scoped>
</style>