<template>
  <div class="card-review">
    <img v-if="rating.user && rating.user.featured_image" :src="`${rating.user.featured_image.path}`"
         style="width: 100px;"
         alt="SmartAdmin WebApp" aria-roledescription="logo">
    <img v-else :src="publicPath + 'media/images/center-detail/man.svg'" style="width: 100px;"
         alt="SmartAdmin WebApp"
         aria-roledescription="logo">

    <div class="detail-review">
      <h4>{{ rating.user ? rating.user.name : '' }}</h4>
      <div class="review">
        <div class="rating left">
          <div class="stars right">
            <el-rate
                v-model="value"
                disabled
                show-score
                text-color="#ff9900"
                score-template="">
            </el-rate>
          </div>
        </div>
      </div>
      <div class="content">
        {{ rating.comments }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardRateAndComment",
  props: ['rating'],
  data() {
    return {
      value: 0,
      publicPath: process.env.VUE_APP_BASE_URL,
    }
  },
  mounted() {
    this.value = this.rating.avg_rate;
  }
}
</script>

<style scoped>

</style>