<template>
  <div class="row">
    <div class="col-md-12">
      <CarouselCourseCombo
          :courses_combo="courses_combo"
          :promotion_branch="promotion_branch"
          :first_classroom_every_courses="first_classroom_every_courses"
          @change-course="emitChangeCourse"
      ></CarouselCourseCombo>

      <Header :firstCourse="first_course" :classroom="classroom"></Header>
    </div>
    <div class="col-md-6">
      <CourseInfo :firstCourse="first_course"></CourseInfo>
      <LessonContent :firstCourse="first_course"></LessonContent>
      <TeacherCourse v-if="classroom.teacher"
                     :teacher="classroom.teacher"
                     :classroom="classroom">
      </TeacherCourse>
      <RateAndComment :ratings="ratings"></RateAndComment>
    </div>
    <div class="col-md-6">
      <ContractRegister
          :firstCourse="first_course"
          :classroom="classroom"
          @openDetail="openDetailStudent"
      ></ContractRegister>
    </div>

    <el-dialog @closed="dialogTableVisible = false" title="Danh sách học viên" :visible.sync="dialogTableVisible">
      <dialog-sudent :students="students"></dialog-sudent>
    </el-dialog>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import '@/assets/css/tuvanvien.scss';
import Header from "../course/components/Header";
import CourseInfo from "./components/CourseInfo";
import LessonContent from "./components/LessonContent";
import TeacherCourse from "./components/TeacherCourse";
import RateAndComment from "./components/RateAndComment";
import ContractRegister from "./components/ContractRegister";
import {TU_VAN_DETAIL_COMBO} from "@/core/services/store/tuVan.module";
import CarouselCourseCombo from "./components/CarouselCourseCombo";
import DialogSudent from "./components/DialogSudent";

export default {
  name: "DetailCourseCombo",
  components: {DialogSudent, CarouselCourseCombo, ContractRegister, RateAndComment, TeacherCourse, LessonContent, CourseInfo, Header},
  data() {
    return {
      courses: [],
      courses_combo: [],
      ratings: [],
      course_ratings: [],
      promotions: {},
      promotion_branch: {},
      first_classroom_every_courses: [],
      first_course: {},
      classroom: {},
      teacher: {},
      customer: null,
      activeName: 'first',
      loading: false,
      students: {},
      dialogTableVisible: false,
    };
  },
  mounted() {
    let payload = {
      promotion_id: this.$route.params.promotion_id,
      customer_id: this.$route.query.customer_id
    };
    this.retrieveComboDetail(payload);
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Chi tiết combo", icon: ''}
    ]);
  },
  methods: {
    openDetailStudent(students) {
      this.dialogTableVisible = true;
      this.students = students;
    },
    emitChangeCourse(course_id_first) {
      let payload = {
        promotion_id: this.$route.params.promotion_id,
        customer_id: this.$route.query.customer_id,
        course_id: course_id_first
      };
      this.retrieveComboDetail(payload);
    },
    retrieveComboDetail(payload) {
      this.loading_course = true;
      this.$store.dispatch(TU_VAN_DETAIL_COMBO, payload)
          .then((response) => {
            this.course_ratings = response.data.courses;
            this.courses_combo = response.data.courses_combo;
            this.course_ratings = response.data.course_ratings;
            this.promotions = response.data.promotions;
            this.promotion_branch = response.data.promotion_branch;
            this.first_classroom_every_courses = response.data.first_classroom_every_courses;
            this.customer = response.data.customer;
            this.ratings = response.data.ratings;
            this.first_course = response.data.firstCourse;
            this.classroom = response.data.classroom;
            this.teacher = response.data.firstClassroom && response.data.firstClassroom.teacher ? response.data.firstClassroom.teacher : {};
            this.loading_course = false;
          });
    }
  },
}
</script>

<style>

</style>