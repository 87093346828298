<template>
  <el-table :data="students">
    <el-table-column property="user_code" label="Mã học viên" width="150"></el-table-column>
    <el-table-column property="name" label="Họ tên" width="300"></el-table-column>
    <el-table-column property="created_at" label="Ngày đăng ký"></el-table-column>
  </el-table>
</template>

<script>
export default {
  name: "DialogSudent",
  props: ['students', 'dialogTableVisible'],
  methods: {

  }
}
</script>
<style scoped>
</style>